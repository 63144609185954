import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {CustomDataTable} from '../../components/CustomDataTable';
import {dateParse} from '../../constants/constants';
import Card from 'react-bootstrap/Card';
import './Eventos.css';
import { getApiData,putApiData,handleApiImage} from '../../api/ApiHandler';


const columns = (handleButtonClick) => ( [

    {
        name: 'Titulo',
        selector: row =>  row.titulo,
        sortable: true,
    },
    {
        name: 'Fecha',
        selector: row => dateParse(row.fecha_creacion),
        sortable: true,
    },
    {
        name: 'Descripción',
        selector: row => row.descripcion.length > 40 ? `${row.descripcion.slice(0, 40)}...` : row.descripcion,
        sortable: false,
    },
	{
        name: "Editar",
        button: true,
        cell: (row) => (
            <button
                className="btn btn-outline btn-xs"
                onClick={(e) => handleButtonClick(e, row.id_evento)}
            >
                ✏️
            </button>
        )
    }	
]);

const expandedComponent = ({ data }) => {
    
    return (
        <Card className="custom-card">
        <div className="custom-card-body">
            <div className="custom-card-image">
            <img src={data.image ?? "../../../media/no-img.jpg"} alt="Card" />
            </div>
            <div className="custom-card-content">
            <h5>{data.titulo}</h5>
            <p>{data.descripcion}</p>
            </div>
        </div>
    </Card>
    );
}
 


const ModalEditEventos = ({ show, handleClose, item, data, setData }) => {
    const [image, setImage] = useState(null);
    const [formData, setFormData] = useState({
        id_evento: -1,
        titulo: '',
        descripcion: '',
    });

    React.useEffect(() => {
        if (item) {
            setFormData({
                id_evento: item.id_evento || -1,
                titulo: item.titulo || '',
                descripcion: item.descripcion || '',
            });
        }
    }, [item]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await putApiData("evento",formData.id_evento,formData);
        if (image) await handleApiImage("PUT",`evento/${formData.id_evento}/image`,image);
        if(response.success){
            formData.image = formData.image || item.image;
            setData(data.map((evento) => (evento.id_evento === formData.id_evento ? {...evento,...formData} : evento)));
        }
        setImage(null);
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Editar Evento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formTitulo">
                        <Form.Label>Titulo</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Titulo"
                            name="titulo"
                            value={formData.titulo}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formDescripcion">
                        <Form.Label>Descripcion</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Descripcion"
                            name="descripcion"
                            value={formData.descripcion}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formImage">
                        <Form.Label>Imagen</Form.Label>
                        <Form.Control
                            type="file"
                            accept="image/*"
                            name="imagen"
                            onChange={handleFileChange}
                        />
                    </Form.Group>
                    <Button className="mt-3" variant="primary" type="submit">
                        Guardar Cambios
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export const EventosList = () => {
    const [Eventos, setEventos] = useState([]);
    const [loading, setLoading] = useState(true);

    const getEventos = async () => {
        const data = await getApiData("evento");
        setEventos(data);
        setLoading(false);
    };
    useEffect(() => {
        getEventos();
    }, []);
    
    return (!loading && <CustomDataTable 
        title="Eventos" 
        columns={columns} 
        list={Eventos} 
        ModalEdit={ModalEditEventos} 
        filterField={"titulo"} 
        cpField={"id_evento"}
        expandedComponent={expandedComponent}
        />);
}