import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container'
import { handleApiImage, postApiData} from '../../api/ApiHandler';


export const EventosForm = ({type = 'evento'}) => {
  const [image, setImage] = useState(null);
  const [formData, setFormData] = useState({
    titulo: '',
    descripcion: '',
    url: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    if(type === "articulo"){
        const response = await postApiData("articulo", formData);
        if(response.success && image) handleApiImage("PUT",`articulo/${response.data?.id}/image`,image);
    }
    else{
        delete formData.url;
        const response = await postApiData("evento", formData);
        if(response.success && image) handleApiImage("PUT",`evento/${response.data?.id_evento}/image`,image);
    }
    setFormData({
      titulo: '',
      descripcion: '',
      url: ''
    });
    setImage(null);
  };

  return (
    <Container className='mt-5'>
      <Form onSubmit={handleSubmit}>
        <Form.Group className="mb-3" controlId="formTitulo">
          <Form.Label>Título</Form.Label>
          <Form.Control
            type="text"
            placeholder="Título"
            name="titulo"
            value={formData.titulo}
            onChange={handleChange}
            required
          />
        </Form.Group>
        
        {type === "articulo" &&
        <Form.Group className="mb-3" controlId="formUrl">
        <Form.Label>URL</Form.Label>
          <Form.Control
            type="text"
            placeholder="URL"
            name="url"
            value={formData.url}
            onChange={handleChange}
            required
          />
        </Form.Group>
        }

        <Form.Group as={Col} controlId="formImage">
          <Form.Label>Imagen</Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            name="imagen"
            onChange={handleFileChange}
          />
        </Form.Group>


        <Form.Group className="mb-3" controlId="formDescripcion">
          <Form.Label>Descripción</Form.Label>
          <Form.Control
            as="textarea"
            rows={4}
            placeholder="Descripción"
            name="descripcion"
            value={formData.descripcion}
            onChange={handleChange}
            required
          />
        </Form.Group>
        

        <Button className="mt-3" variant="primary" type="submit">
          Guardar
        </Button>
      </Form>
    </Container>
  );
};
