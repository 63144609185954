import React from "react";
import "./Login.css";
import { Navbar, Container, } from "react-bootstrap";
import {LoginForm} from "./LoginForm";



export const Login = ({ setToken }) => {
    return (
        <div className="bdy">
            <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
            <Container>
                <Navbar.Brand href="/">
                <img
                alt=""
                src="/media/logo.png"
                height="30"
                className="d-inline-block align-top"
                />{' '}
                Agribusiness Ecuador
                </Navbar.Brand>
            </Container>
            </Navbar>
            <div className="sign-in-box">
                <h1>Admin Login</h1>
                <LoginForm setToken={setToken}/>
            </div>
        </div>
    );
}
