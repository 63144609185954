import React from "react";
import {Button, Form} from 'react-bootstrap';
import { PostLogin } from "../../api/ApiHandler";
import { setGlobalToken } from "../../api/ApiHandler";

export const LoginForm = ({setToken }) => {

    const [user, setUser] = React.useState({
        cedula: "",
        password: "",
    });

    const handleSubmit = async (event) => {
        event.preventDefault();
        console.log(user);
        const response = await PostLogin(user);
        if(response.token){
            localStorage.setItem('loggedUser', response.token);
            setToken(response.token);
            setGlobalToken(response.token);
        }
    };

    const handleChange = (event) => {
        setUser({
            ...user,
            [event.target.name]: event.target.value,
        });
    };

    return(
    <Form onSubmit={handleSubmit}>

      <Form.Group className="mb-3">
        <Form.Label>Usuario</Form.Label>
        <Form.Control type="text" name="cedula" placeholder="Usuario" onChange={handleChange}/>
      </Form.Group>

      <Form.Group className="mb-3">
        <Form.Label>Contraseña</Form.Label>
        <Form.Control type="password" name="password" placeholder="Constraseña" onChange={handleChange}/>
      </Form.Group>
      
      <Button variant="outline-light" type="submit">
        Enviar
      </Button>
    </Form>

    );
}