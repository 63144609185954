import React from 'react';
import { Navigation } from './navigation/navigation';
import { Login } from './vistas/Home/Login';
import { Loading } from './constants/constants';
import { setGlobalToken } from './api/ApiHandler';





function App() {
  const [token, setToken] = React.useState("");
  const [loading, setLoading] = React.useState(true);

  const handleLogout = () => {
    localStorage.removeItem('loggedUser');
    setToken("");
    setGlobalToken("");
  };

  React.useEffect(() => {
    const loggedUser = localStorage.getItem('loggedUser');
    if (loggedUser) {
      setToken(loggedUser);
      setGlobalToken(loggedUser);
    } setLoading(false);
  }, []);
  if(loading){
    return(<Loading/>);
  }else{
  if(token === ""){
    return(<Login setToken={setToken}/>);
  } else return(<Navigation handleLogout={handleLogout}/>);
  }

}


export default App;