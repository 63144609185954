import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import {CustomDataTable} from '../../components/CustomDataTable';
import {dateParse} from '../../constants/constants';
import Card from 'react-bootstrap/Card';
import '../Eventos/Eventos.css';
import { getApiData,putApiData,handleApiImage} from '../../api/ApiHandler';


const columns = (handleButtonClick) => ( [

    {
        name: 'Titulo',
        selector: row =>  <a target="_blank" rel="noreferrer" href={row.url}>{row.titulo}</a>,
        sortable: true,
    },
    {
        name: 'Fecha',
        selector: row => dateParse(row.fecha_creacion),
        sortable: true,
    },
    {
        name: 'Descripción',
        selector: row => row.descripcion.length > 40 ? `${row.descripcion.slice(0, 40)}...` : row.descripcion,
        sortable: false,
    },
	{
        name: "Editar",
        button: true,
        cell: (row) => (
            <button
                className="btn btn-outline btn-xs"
                onClick={(e) => handleButtonClick(e, row.id)}
            >
                ✏️
            </button>
        )
    }	
]);

const expandedComponent = ({ data }) => {
    
    return (
        <Card className="custom-card">
        <div className="custom-card-body">
            <div className="custom-card-image">
            <img src={data.image ?? "../../../media/no-img.jpg"} alt="imagen del evento" />
            </div>
            <div className="custom-card-content">
            <h5>{data.titulo}</h5>
            <a href={data.url}>{data.url}</a>
            <p>{data.descripcion}</p>
            </div>
        </div>
    </Card>
    );
}
 


const ModalEditArticulos = ({ show, handleClose, item, data, setData }) => {
    const [image,setImage] = useState(null);
    const [formData, setFormData] = useState({
        id: -1,
        titulo: '',
        descripcion: '',
        url: ''
    });

    React.useEffect(() => {
        if (item) {
            setFormData({
                id: item.id || -1,
                titulo: item.titulo || '',
                descripcion: item.descripcion || '',
                url: item.url || ''
            });
        }
    }, [item]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setImage(file);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const response = await putApiData("articulo",formData.id,formData);
        if(image) await handleApiImage("PUT",`articulo/${formData.id}/image`,image);
        
        if(response.success){
            formData.append('image', image || item.image);
            setData(data.map((articulo) => (articulo.id === formData.id ? {...articulo,...formData} : articulo)));
        } 
        setImage(null);
        handleClose();
    };

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Editar Evento</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formTitulo">
                        <Form.Label>Titulo</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Titulo"
                            name="titulo"
                            value={formData.titulo}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formUrl">
                        <Form.Label>URL</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="URL"
                            name="url"
                            value={formData.url}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formDescripcion">
                        <Form.Label>Descripcion</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            placeholder="Descripcion"
                            name="descripcion"
                            value={formData.descripcion}
                            onChange={handleChange}
                        />
                    </Form.Group>
                    <Form.Group controlId="formImage">
                        <Form.Label>Imagen</Form.Label>
                        <Form.Control
                            type="file"
                            accept="image/*"
                            onChange={handleFileChange}
                        />
                    </Form.Group>
                    <Button className="mt-3" variant="primary" type="submit">
                        Guardar Cambios
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export const ArticulosList = () => {
    const [articulos, setArticulos] = useState([]);
    const [loading, setLoading] = useState(true);

    const getArticulos = async () => {
        const data = await getApiData("articulo");
        setArticulos(data);
        setLoading(false);
    };
    useEffect(() => {
        getArticulos();
    }, []);
    
    return (!loading && <CustomDataTable 
        title="Eventos" 
        columns={columns} 
        list={articulos} 
        ModalEdit={ModalEditArticulos} 
        filterField={"titulo"} 
        cpField={"id"}
        expandedComponent={expandedComponent}
        />);
}