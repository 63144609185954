import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container'
import  Modal from 'react-bootstrap/Modal';
import { getApiData, postApiData, handleApiFile,handleApiImage } from '../../api/ApiHandler';


const ProductosNew = () => {
  const [ficha, setFicha] = useState(null);
  const [portada, setPortada] = useState(null);
  const [Proveedores, setProveedores] = useState([]);
  const [formData, setFormData] = useState({
    precio: '',
    nombre: '',
    presentacion: '',
    descripcion: '',
    id_proveedor: -1,
    iva: 0
  });

  const getProovedores = async() => {
    const data = await getApiData("proveedor");
    setProveedores(data);
  }

  useEffect(() => {
    getProovedores();
  }, []);


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleFileChange = (e) => {
    const name = e.target.name;
    const file = e.target.files[0];
    if(name === "ficha_tecnica") setFicha(file);
    else setPortada(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    formData.precio = parseFloat(formData.precio);
    formData.iva = parseFloat(formData.iva);
    formData.id_proveedor = parseInt(formData.id_proveedor);
    const response = await postApiData("producto", formData);
    if(response.success){
      if(ficha) await handleApiFile("PUT",`producto/${response.data?.id_producto}/ficha`,ficha);
      if(portada)await handleApiImage("POST",`producto/${response.data?.id_producto}/portada`,portada);

    }

    setFormData({
      precio: '',
      nombre: '',
      presentacion: '',
      descripcion: '',
      id_proveedor: -1,
      ficha_tecnica: null,
      portada: null,
      iva: 0
    });
    console.log(formData);
  };

  return (
    <Container className='mt-5'>
    <Form onSubmit={handleSubmit}>
      <Row className="mb-3">
        <Col xs={8}>
        <Form.Group as={Col} controlId="formNombre">
          <Form.Label>Nombre</Form.Label>
          <Form.Control
            type="text"
            placeholder="Nombre"
            name="nombre"
            value={formData.nombre}
            onChange={handleChange}
            required
          />
        </Form.Group>
        </Col>
        <Form.Group as={Col} controlId="formPrecio">
          <Form.Label>Precio</Form.Label>
          <Form.Control
            type="number"
            placeholder="Precio"
            name="precio"
            value={formData.precio}
            onChange={handleChange}
            required
          />
        </Form.Group>
         
        <Form.Group as={Col} controlId="formIva">
          <Form.Label>IVA</Form.Label>
          <Form.Select
            name="iva"
            value={formData.iva}
            onChange={handleChange}
            required
          >
            <option key={0} value={0}>0%</option>
            <option key={1} value={10.5}>10.5%</option>
            <option key={2} value={21}>21%</option>
          </Form.Select>
        </Form.Group>     

      </Row>

      <Row className="mb-3">
        <Form.Group as={Col} controlId="formPresentacion">
          <Form.Label>Presentacion</Form.Label>
          <Form.Control 
            type="text"
            placeholder="Presentacion"
            name="presentacion"
            value={formData.presentacion}
            onChange={handleChange}
            required
          />
        </Form.Group>
        <Form.Group as={Col} controlId="formProveedor">
          <Form.Label>Proveedor</Form.Label>
          <Form.Select
            name="id_proveedor"
            value={formData.id_proveedor}
            onChange={handleChange}
            required
          >
            <option key={-1} value={-1}>Selecciona un Proveedor</option>
            {Proveedores.map((proveedor) => (
                        <option key={proveedor.id_proveedor} value={proveedor.id_proveedor}>{proveedor.nombre}</option>
                    ))}
          </Form.Select>
        </Form.Group>
      </Row>

      <Form.Group as={Col} controlId="formDescripcion" className="mb-3">
          <Form.Label>Descripcion</Form.Label>
          <Form.Control
            as="textarea" 
            rows={4}
            placeholder="Descripcion"
            name="descripcion"
            value={formData.descripcion}
            onChange={handleChange}
            required
          />
        </Form.Group>

      <Row className="mb-3"> 
        
        <Form.Group as={Col} controlId="formFicha">
          <Form.Label>Ficha Tecnica</Form.Label>
          <Form.Control
            type="file"
            accept="application/pdf"
            name="ficha_tecnica"
            onChange={handleFileChange}
          />
        </Form.Group>
        <Form.Group as={Col} controlId="formportada">
          <Form.Label>Portada</Form.Label>
          <Form.Control
            type="file"
            accept="image/*"
            name="portada"
            onChange={handleFileChange}
          />
        </Form.Group>
      </Row>

      <Button className="mt-3" variant="primary" type="submit">
        Guardar
      </Button>
    </Form>
    </Container>
  );
};


export const ProductosForm = () => {
  return ( 
    <Container className='mt-5'>
      <h3 className='mb-8'>Importar desde archivo CSV</h3>
      <ProductosCSV className='mt-5'/>
      <hr/>
      <h3>Cargar nuevo producto</h3>
      <ProductosNew/>
      </Container>
  );
};



const ProductosCSV = () => {
  const [showModal, setShowModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    console.log(selectedFile);
    if (selectedFile) {
      handleApiFile("POST","producto/list", selectedFile);
    }
    setShowModal(false);
  };

  return (
    <>
      <Button variant="success" onClick={() => setShowModal(true)}>
        Cargar CSV
      </Button>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Cargar CSV</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Form.Group controlId="formFile" className="mb-3">
              <Form.Label>Selecciona un archivo CSV</Form.Label>
              <Form.Control
                type="file"
                accept=".csv"
                onChange={handleFileChange}
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Cargar
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
