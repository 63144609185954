import React from 'react';
import './Login.css';


export const Home = () => {
  return (
    <div className="custom-container bdy">
        <h1 className='animado'>Agribusiness Ecuador</h1>
    </div>
  );
}
