import {CustomDataTable} from "../../components/CustomDataTable";
import React,{useState,useEffect} from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getApiData,putApiData,handleApiImage,handleApiFile} from '../../api/ApiHandler';
import {DescargaIcon } from '../../constants/constants';




const columns = (handleButtonClick) => ( [
    {
		name: 'Nombre',
		selector: row => row.nombre,
		sortable: true,
	},
	{
		name: 'Precio',
		selector: row => row.precio,
		sortable: true,
	},
	{
		name: 'Presentacion',
		selector: row => row.presentacion,
		sortable: false,
	},
	{
		name: 'Proveedor',
		selector: row => row.nombre_proveedor,
		sortable: true,
	},
	{
		name: 'IVA',
		selector: row => row.iva,
		sortable: true,
	},

    {
        name: "Descargar",
        button: true,
        cell: (row) => ( <a target="_blank" rel="noreferrer" href={row.ficha_tecnica} download> <DescargaIcon/> </a>)
    },
	{
        name: "Editar",
        button: true,
        cell: (row) => (
            <button
                className="btn btn-outline btn-xs"
                onClick={(e) => handleButtonClick(e, row.id_producto)}
            >
                ✏️
            </button>
        )
    }

	
]);





const ModalEditProductos = ({ show, handleClose,item,data,setData}) => {
	const [formData, setFormData] = useState({
		id_producto: -1,
		precio: '',
		nombre: '',
		presentacion: '',
		descripcion: '',
		nombre_proveedor: '',
		iva: 0
	  });
	const [ficha, setFicha] = useState(null);
	const [portada, setPortada] = useState(null);
	
	React.useEffect(() => {
		if (item) {
		  setFormData({
			id_producto: item.id_producto || -1,
			precio: item.precio || '',
			nombre: item.nombre || '',
			presentacion: item.presentacion || '',
			descripcion: item.descripcion || '',
			nombre_proveedor: item.nombre_proveedor|| '',
			iva: item.iva || 0
		  });
		}
	  }, [item]);

										
  
	const handleChange = (e) => {
	  const { name, value } = e.target;
	  setFormData({
		...formData,
		[name]: value
	  });
	};
  
	const handleFileChange = (e) => {
		const name = e.target.name;
		const file = e.target.files[0];
		if(name === "ficha_tecnica") setFicha(file);
		else setPortada(file);
	};
  
	const handleSubmit = async (e) => {
	  e.preventDefault();
	  formData.precio = parseFloat(formData.precio);
	  formData.iva = parseFloat(formData.iva);
	  const response = await putApiData("producto",formData.id_producto,formData);

	  if(ficha)await handleApiFile("PUT",`producto/${formData.id_producto}/ficha`,ficha);
      if(portada)await handleApiImage("POST",`producto/${formData.id_producto}/portada`,portada);
	  if(response.success)setData(data.map((producto) => (producto.id_producto === formData.id_producto ? formData : producto)));
	  setFicha(null);
	  setPortada(null);
	  handleClose();
	};
  
	return (
	  <Modal show={show} onHide={handleClose}>
		<Modal.Header closeButton>
		  <Modal.Title>Editar Usuario</Modal.Title>
		</Modal.Header>
		<Modal.Body>
		  <Form onSubmit={handleSubmit}>
			<Form.Group controlId="formPrecio">
			  <Form.Label>Precio</Form.Label>
			  <Form.Control
				type="number"
				placeholder="Precio"
				name="precio"
				step={0.01}
				value={formData.precio}
				onChange={handleChange}
			  />
		<Form.Group  controlId="formIva">
          <Form.Label>IVA</Form.Label>
          <Form.Select
            name="iva"
            value={formData.iva}
            onChange={handleChange}
            required
          >
            <option key={0} value={0}>0%</option>
            <option key={1} value={10.5}>10.5%</option>
            <option key={2} value={21}>21%</option>
          </Form.Select>
        </Form.Group>
			</Form.Group>
			<Form.Group controlId="formNombre">
			  <Form.Label>Nombre</Form.Label>
			  <Form.Control
				type="text"
				placeholder="Nombre"
				name="nombre"
				value={formData.nombre}
				onChange={handleChange}
			  />
			</Form.Group>
            <Form.Group controlId="formPresentacion">
                <Form.Label>Presentacion</Form.Label>
                <Form.Control 
                    type="text"
                    placeholder="Presentacion"
                    name="presentacion"
                    value={formData.presentacion}
                    onChange={handleChange}
                />
            </Form.Group>
            <Form.Group controlId="formDescripcion">
                <Form.Label>Descripcion</Form.Label>
                <Form.Control
                    as="textarea" 
                    rows={3}
                    placeholder="Descripcion"
                    name="descripcion"
                    value={formData.descripcion}
                    onChange={handleChange}
                />
            </Form.Group>
            
            <Form.Group controlId="formFicha">
                <Form.Label>Ficha Tecnica</Form.Label>
                <Form.Control
					name="ficha_tecnica"
                    type="file"
                    accept="application/pdf"
                    onChange={handleFileChange}
                />
            </Form.Group>
			<Form.Group controlId="formImagen">
			  <Form.Label>Portada</Form.Label>
			  <Form.Control
			 	name="portada"
				type="file"
				accept="image/*"
				onChange={handleFileChange}
			  />
			</Form.Group>
			<Button className="mt-3" variant="primary" type="submit">
			  Guardar Cambios
			</Button>
		  </Form>
		</Modal.Body>
	  </Modal>
	);
  };



export const ProductosList = () => {
	const [Productos, setProductos] = useState([]);
	const [loading, setLoading] = useState(true);

	const getProductos = async () => {		
		const data = await getApiData("producto");
		setProductos(data);
		setLoading(false);
	};
	useEffect(() => {
		getProductos();
	},[]);

    return (!loading && <CustomDataTable title="Productos" columns={columns} list={Productos} ModalEdit={ModalEditProductos} filterField={"nombre"} cpField={"id_producto"}/>);
}