import React, { useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import { postApiData,getApiData } from '../../api/ApiHandler';

export const UsuariosForm = () => {
  const [formData, setFormData] = useState({
    cedula: '',
    cod_cargo: '',
    cod_zona: '',
    nombre: '',
    correo: '',
    telefono: '',
    direccion: '',
    password: '',
    rol: 'colaborador',
  });

  const [cargos, setCargos] = useState([]);
  const fetchCargos = async () => {
    const data = await getApiData("cargo");
    setCargos(data);
  };
  React.useEffect(() => {
    fetchCargos();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };



  const handleSubmit = (e) => {
    e.preventDefault();
    formData.cod_cargo = formData.rol === 'colaborador' ? parseInt(formData.cod_cargo) : undefined;
    formData.cod_zona = formData.cod_zona ? parseInt(formData.cod_zona) : undefined;
    postApiData("persona", formData);
  };

  return (
    <Container className='mt-5'>
      <Form onSubmit={handleSubmit}>
        <Row className="mb-3">
          <Col>
            <Form.Label>Cédula</Form.Label>
            <Form.Control
              type="number"
              name="cedula"
              value={formData.cedula}
              onChange={handleChange}
              required
            />
          </Col>
          <Col>
            <Form.Label>Contraseña</Form.Label>
            <Form.Control
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </Col>
        </Row>
        
        <Row className="mb-3">
        <Col>
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              type="text"
              name="nombre"
              value={formData.nombre}
              onChange={handleChange}
              required
            />
          </Col>
          <Col>
        <Form.Group className="mb-3">
          <Form.Label>Dirección</Form.Label>
          <Form.Control
            type="text"
            name="direccion"
            value={formData.direccion}
            onChange={handleChange}
            required
          />
        </Form.Group>
        </Col>
        </Row>
        <Row className="mb-3">
          <Col>
            <Form.Label>Correo</Form.Label>
            <Form.Control
              type="email"
              name="correo"
              value={formData.correo}
              onChange={handleChange}
              required
            />
          </Col>
          <Col>
            <Form.Label>Teléfono</Form.Label>
            <Form.Control
              type="tel"
              name="telefono"
              value={formData.telefono}
              onChange={handleChange}
              required
            />
          </Col>
        </Row>
        <Row className="mb-3">
        <Col>
        <Form.Group className="mb-3">
          <Form.Label>Rol</Form.Label>
          <Form.Control
            as="select"
            name="rol"
            value={formData.rol}
            onChange={handleChange}
          >
            <option value="colaborador">Colaborador</option>
            <option value="cliente">Cliente</option>
          </Form.Control>
        </Form.Group>
        </Col>
        <Col>
            <Form.Label>Código Zona</Form.Label>
            <Form.Control
              type="number"
              name="cod_zona"
              value={formData.cod_zona}
              onChange={handleChange}
              required
            />
          </Col>
          <Col>
          <Form.Label>Cargo</Form.Label>
			<Form.Select
			  name="cod_cargo"
			  value={formData.cod_cargo}
			  onChange={handleChange}
			  disabled={formData.rol !== 'colaborador'}
			>
			  <option value={undefined}>No asignado</option>
			  {cargos.map((cargo) => (
				<option key={cargo.cod_cargo} value={cargo.cod_cargo}>
				  {cargo.nombre}
				</option>
			  ))}
			</Form.Select>
          </Col>
        </Row>
        <Button variant="primary" type="submit">
          Guardar
        </Button>
      </Form>
    </Container>
  );
};

export default UsuariosForm;
