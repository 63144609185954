import React from "react";
import Spinner from 'react-bootstrap/Spinner';

export const RutaPorCP = (cp) => {
  switch (cp) {
    case "cedula": return "persona";
    case "id_producto": return "producto";
    case "id_notificacion": return "notificacion";
    case "id_evento": return "evento";
    default: return "";
  }
};

  

export const DescargaIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-download" viewBox="0 0 16 16">
  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z"/>
</svg>
)

export const Loading = () => {
    return (
        <Spinner 
            style={{top: '50%',left: '50%',position: 'absolute', transform: 'translate(-50%, -50%)'}} 
            animation="border" 
            role="status"
        />
    );
}

export const Usuarios = [
    { cedula: 123456789, id_depto: 1, cod_zona: 101, nombre: "Juan Pérez", correo: "juan.perez@example.com", telefono: "555-123-4567", direccion: "Calle 123, Ciudad", rol: "colaborador" },
    { cedula: 987654321, id_depto: 2, cod_zona: 202, nombre: "María González", correo: "maria.gonzalez@example.com", telefono: "555-987-6543", direccion: "Av. Principal, Pueblo", rol: "cliente" },
    { cedula: 234567890, id_depto: 3, cod_zona: 303, nombre: "Pedro García", correo: "pedro.garcia@example.com", telefono: "555-234-5678", direccion: "Plaza Central, Villa", rol: "colaborador" },
    { cedula: 876543210, id_depto: 4, cod_zona: 404, nombre: "Ana Martínez", correo: "ana.martinez@example.com", telefono: "555-876-5432", direccion: "Carrera 45, Ciudad", rol: "cliente" },
    { cedula: 345678901, id_depto: 5, cod_zona: 505, nombre: "Luis Rodríguez", correo: "luis.rodriguez@example.com", telefono: "555-345-6789", direccion: "Paseo del Sol, Pueblo", rol: "colaborador" },
    { cedula: 765432109, id_depto: 6, cod_zona: 606, nombre: "Laura Sánchez", correo: "laura.sanchez@example.com", telefono: "555-765-4321", direccion: "Av. Libertad, Ciudad", rol: "cliente" },
    { cedula: 456789012, id_depto: 7, cod_zona: 707, nombre: "Carlos López", correo: "carlos.lopez@example.com", telefono: "555-456-7890", direccion: "Calle Mayor, Villa", rol: "colaborador" },
    { cedula: 654321098, id_depto: 8, cod_zona: 808, nombre: "Elena Fernández", correo: "elena.fernandez@example.com", telefono: "555-654-3210", direccion: "Paseo del Río, Pueblo", rol: "cliente" },
    { cedula: 567890123, id_depto: 9, cod_zona: 909, nombre: "Javier Pérez", correo: "javier.perez@example.com", telefono: "555-567-8901", direccion: "Callejón Oscuro, Ciudad", rol: "colaborador" },
    { cedula: 543210987, id_depto: 10, cod_zona: 1010, nombre: "Sofía Martínez", correo: "sofia.martinez@example.com", telefono: "555-543-2109", direccion: "Rincón Tranquilo, Villa", rol: "cliente" }
  ];
  
  export const Proveedores = [
    {
      id: 1,
      nombre: "Juan Perez"
    },
    {
      id: 2,
      nombre: "Maria Martinez"
    },
    {
      id: 3,
      nombre: "Mauro Gonzalez"
    },
    {
      id: 4,
      nombre: "Soledad Rodriguez"
    }
  ];
  
  
  export const Productos = [
    {
        id_producto: 1,
        precio: "10.02",
        nombre: "Producto 1",
        presentacion: "Presentación 1",
        descripcion: "Descripción 1",
        ficha_tecnica: "../fichas_tecnicas/ejemplo.pdf",
        imagen: "https://naturespath.com/cdn/shop/articles/planting-seeds.jpg?v=1639148471",
        proveedor: "Maria Martinez"
      },
      {
        id_producto: 2,
        precio: "15.3",
        nombre: "Producto 2",
        presentacion: "Presentación 2",
        descripcion: "Descripción 2",
        ficha_tecnica: "../fichas_tecnicas/ejemplo.pdf",
        imagen: "https://naturespath.com/cdn/shop/articles/planting-seeds.jpg?v=1639148471",
        proveedor: "Juan Perez"
      },
      {
        id_producto: 3,
        precio: "25",
        nombre: "Producto 3",
        presentacion: "Presentación 3",
        descripcion: "Descripción 3",
        ficha_tecnica: "../fichas_tecnicas/ejemplo.pdf",
        imagen: "https://naturespath.com/cdn/shop/articles/planting-seeds.jpg?v=1639148471",
        proveedor: "Mauro Gonzalez"
      },
      {
        id_producto: 4,
        precio: "8",
        nombre: "Producto 4",
        presentacion: "Presentación 4",
        descripcion: "Descripción 4",
        ficha_tecnica: "../fichas_tecnicas/ejemplo.pdf",
        imagen: "https://naturespath.com/cdn/shop/articles/planting-seeds.jpg?v=1639148471",
        proveedor: "Soledad Rodriguez"
      },
      
    ];

  export const dateParse = (date) => {
    let dateArray = date.split("-");
    let year = dateArray[0];
    let month = dateArray[1];
    let dayArray = dateArray[2].split("T");
    let day = dayArray[0];
    let newDate = day + "/" + month + "/" + year;
    return newDate;
  };

  export const Notificaciones = [
    {
      id_notificacion: 1,
      titulo: "Nueva actualización disponible",
      descripcion: "¡Una nueva actualización está disponible! Haz clic para obtener más detalles."
    },
    {
      id_notificacion: 2,
      titulo: "Recordatorio de reunión",
      descripcion: "Recuerda que tenemos una reunión programada para hoy a las 3:00 PM en la sala de conferencias."
    },
    {
      id_notificacion: 3,
      titulo: "Notificación importante",
      descripcion: "Se ha detectado un problema crítico en el sistema. Por favor, toma medidas inmediatas."
    },
    {
      id_notificacion: 4,
      titulo: "Feliz cumpleaños",
      descripcion: "Hoy es el cumpleaños de Juan. ¡No olvides desearle un feliz cumpleaños!"
    },
    {
      id_notificacion: 5,
      titulo: "Actualización de políticas",
      descripcion: "Hemos actualizado nuestras políticas de privacidad. Por favor, revisa los cambios antes del 30 de septiembre."
    },
    {
      id_notificacion: 6,
      titulo: "Nuevo mensaje recibido",
      descripcion: "Has recibido un nuevo mensaje de contacto. Por favor, revisa tu bandeja de entrada."
    },
    {
      id_notificacion: 7,
      titulo: "Evento especial esta semana",
      descripcion: "No te pierdas el evento especial que tendremos esta semana. ¡Esperamos verte allí!"
    }
  ];
  

   export const Eventos = [
      {
        id_evento: 1,
        titulo: "Conferencia de Tecnología",
        descripcion: "Una conferencia sobre las últimas tendencias tecnológicas.",
        fecha_creacion: "2023-09-07",
        image: "https://blog.visitacostadelsol.com/hubfs/1-Aug-26-2020-09-51-11-94-AM.jpg"
      },
      {
        id_evento: 2,
        titulo: "Concierto en Vivo",
        descripcion: "¡Únete a nosotros para una noche llena de música en vivo y diversión!",
        fecha_creacion: "2023-09-10",
        image: "https://fotos.perfil.com/2022/02/11/trim/987/555/0211concierto-1311358.jpg"
      },
      {
        id_evento: 3,
        titulo: "Feria de Libros",
        descripcion: "Explora una amplia selección de libros de diferentes géneros.",
        fecha_creacion: "2023-09-15",
        image: "https://images.pagina12.com.ar/styles/focal_16_9_960x540/public/2023-05/713411-feria-20del-20libro.jpg"
      },
      {
        id_evento: 4,
        titulo: "Curso de Fotografía",
        descripcion: "Aprende las habilidades esenciales para tomar fotos impresionantes.",
        fecha_creacion: "2023-09-20",
        image: "https://www.slideteam.net/media/catalog/product/cache/1280x720/c/h/chronological_timeline_with_various_milestones_and_events_slide01.jpg"
      },
      {
        id_evento: 5,
        titulo: "Torneo de Ajedrez",
        descripcion: "Participa en nuestro torneo de ajedrez y demuestra tus habilidades.",
        fecha_creacion: "2023-09-25",
        image: "https://www.slideteam.net/media/catalog/product/cache/1280x720/c/h/chronological_timeline_with_various_milestones_and_events_slide01.jpg"
      },
      {
        id_evento: 6,
        titulo: "Seminario de Marketing Digital",
        descripcion: "Descubre las estrategias más efectivas de marketing en línea.",
        fecha_creacion: "2023-09-30",
        image: "https://www.slideteam.net/media/catalog/product/cache/1280x720/c/h/chronological_timeline_with_various_milestones_and_events_slide01.jpg"
      },
      {
        id_evento: 7,
        titulo: "Fiesta de Aniversario",
        descripcion: "Celebremos juntos otro año de éxitos y logros.",
        fecha_creacion: "2023-10-05",
        image: "https://www.slideteam.net/media/catalog/product/cache/1280x720/c/h/chronological_timeline_with_various_milestones_and_events_slide01.jpg"
        }
    ];