import React, { useState, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Swal from 'sweetalert2';
import { deleteApiData } from '../api/ApiHandler';
import { RutaPorCP } from '../constants/constants';

export const CustomDataTable = ({ 
  title,
  list, 
  columns, 
  ModalEdit, 
  filterField,
  cpField,
  expandedComponent
}) => {
  const[data, setData] = useState(list);
  const [modalShow, setModalShow] = useState(false);
  const [itemToEdit, setItemToEdit] = useState({});
  const [filterText, setFilterText] = useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);

  const handleRowSelected = React.useCallback(state => {
    setSelectedRows(state.selectedRows);
  }, []);

  const filteredItems = data.filter(
    item => item[filterField] && item[filterField].toLowerCase().includes(filterText.toLowerCase()),
  );

  const contextActions = useMemo(() => {
    const handleDelete = () => {
      Swal.fire({
        icon: 'question',
        title: 'Desea borrar los elementos seleccionados?',
        showDenyButton: true,
        confirmButtonText: 'Borrar',
        denyButtonText: `Cancelar`,
      }).then((result) => {
        if (result.isConfirmed) {
          selectedRows.map(item => deleteApiData(RutaPorCP(cpField), item[cpField]));
          setToggleCleared(!toggleCleared);
          setData(data.filter(r => !selectedRows.includes(r)));
        }
      })
    };

    return (
      <Button key="delete" onClick={handleDelete} variant="danger">
        Delete
      </Button>
    );
  }, [data, selectedRows, toggleCleared,cpField]);

  const subHeaderComponentMemo = useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText('');
      }
    };

    return (
      <InputGroup>
      <Form.Control 
        id="search"
        type="text"
        placeholder={`Buscar por ${filterField} ...`} 
        value={filterText}
        onChange={e => setFilterText(e.target.value)} />
      <Button variant="outline-secondary" onClick={handleClear}>x</Button>
	  </InputGroup>
    );
  }, [filterText, resetPaginationToggle,filterField]);

  const handleEditClick = (e, cp) => {
    e.preventDefault();   
    setItemToEdit(data.find(item => item[cpField] === cp));
    console.log(itemToEdit);
    setModalShow(true);
  };

  const handleClose = () => setModalShow(false);

  return (
    <div className='container mt-3'>
      <ModalEdit show={modalShow} handleClose={handleClose} item={itemToEdit} data={data} setData={setData} />
      <DataTable
        title={title}
        columns={columns(handleEditClick)}
        data={filteredItems}
        pagination
        paginationResetDefaultPage={resetPaginationToggle}
        subHeader
        subHeaderComponent={subHeaderComponentMemo}
        selectableRows
        persistTableHead
        contextActions={contextActions}
        onSelectedRowsChange={handleRowSelected}
        clearSelectedRows={toggleCleared}
        expandableRows={typeof expandedComponent !== 'undefined'}
        expandableRowsComponent={typeof expandedComponent !== 'undefined' && expandedComponent}
      />
    </div>
  );
};

