import React from "react";
import { Route, Routes } from 'react-router-dom';
import { NavBar } from "../components/NavBar";
import { UsuariosList } from "../vistas/Usuarios/UsuariosList";
import { UsuariosForm } from "../vistas/Usuarios/UsuariosForm";
import { ProductosList } from "../vistas/Productos/ProductosList";
import { ProductosForm } from "../vistas/Productos/ProductosForm";
import { EventosForm } from "../vistas/Eventos/EventosForm";
import { EventosList } from "../vistas/Eventos/EventosList";
import { ArticulosList } from "../vistas/ArticulosTecnicos/ArticulosList";
import { Home } from "../vistas/Home/Home";


export const Navigation = ({handleLogout}) => {
    return (
      <>
      <NavBar handleLogout={handleLogout}/>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/alta-usuarios" element={<UsuariosForm/>} />
        <Route path="/lista-usuarios" element={<UsuariosList/>} />
        <Route path="/lista-productos" element={<ProductosList/>}/>
        <Route path="/alta-productos" element={<ProductosForm/>}/>
        <Route path="/alta-eventos" element={<EventosForm/>}/>
        <Route path="/lista-eventos" element={<EventosList/>}/>
        <Route path="/alta-articulos" element={<EventosForm type="articulo"/>} />
        <Route path="/lista-articulos" element={<ArticulosList/>} />
        <Route path="*" element={<h1>Error 404, Pagina no encontrada</h1>} />
      </Routes>
      </>
    )
}
