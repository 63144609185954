import {CustomDataTable} from "../../components/CustomDataTable";
import React,{useEffect, useState} from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { getApiData,putApiData } from '../../api/ApiHandler';


const columns = (handleButtonClick) => ( [
    {
		name: 'Cedula',
		selector: row => row.cedula,
		sortable: true,
	},
	{
		name: 'Nombre',
		selector: row => row.nombre,
		sortable: true,
	},
	{
		name: 'Correo',
		selector: row => row.correo,
		sortable: false,
	},
	{
		name: 'Rol',
		selector: row => row.rol,
		sortable: true,
	},
	{
        name: "Editar",
        button: true,
        cell: (row) => (
            <button
                className="btn btn-outline btn-xs"
                onClick={(e) => handleButtonClick(e, row.cedula)}
            >
                ✏️
            </button>
        )
    }

	
]);



const ModalEditUsuarios = ({ show, handleClose,item,data,setData}) => {
	const [formData, setFormData] = useState({
		cedula: '',
		cod_zona: '',
		nombre: '',
		correo: '',
		telefono: '',
		direccion: '',
		rol: '',
		password: '',
		cod_cargo: ''
	  });
	  const [cargos, setCargos] = useState([]);
	
	React.useEffect(() => {
		if (item) {
		  setFormData({
			cedula: item.cedula || '',
			cod_zona: item.cod_zona || '',
			nombre: item.nombre || '',
			correo: item.correo || '',
			telefono: item.telefono || '',
			direccion: item.direccion || '',
			rol: item.rol || '',
			cod_cargo: item.cod_cargo || '',
		  });
		}
	  }, [item]);

	  React.useEffect(() => {
		getCargos();
	  }, []);
										
	const getCargos = async () => {		
		const data = await getApiData("cargo");
		setCargos(data);
	};
	const handleChange = (e) => {
	  const { name, value } = e.target;
	  setFormData({
		...formData,
		[name]: value
	  });
	};
  

  
	const handleSubmit = async(e) => {
	  e.preventDefault();
	  formData.cod_cargo = formData.rol === 'colaborador' ? parseInt(formData.cod_cargo) : undefined;
      formData.cod_zona = parseInt(formData.cod_zona)
	  formData.telefono = formData.telefono === '' ? undefined : formData.telefono;
	  formData.password = formData.password === '' ? undefined : formData.password;
	  const response = await putApiData("persona",formData.cedula,formData);
	  if(response.success) setData(data.map((usuario) => (usuario.cedula === formData.cedula ? formData : usuario)));
  	  handleClose();
	};
  
	return (
	  <Modal show={show} onHide={handleClose}>
		<Modal.Header closeButton>
		  <Modal.Title>Editar Usuario</Modal.Title>
		</Modal.Header>
		<Modal.Body>
		  <Form onSubmit={handleSubmit}>
			<Form.Group controlId="formZona">
			  <Form.Label>Código Zona</Form.Label>
			  <Form.Control
				type="number"
				placeholder="Código Zona"
				name="cod_zona"
				value={formData.cod_zona}
				onChange={handleChange}
			  />
			</Form.Group>
			<Form.Group controlId="formNombre">
			  <Form.Label>Nombre</Form.Label>
			  <Form.Control
				type="text"
				placeholder="Nombre"
				name="nombre"
				value={formData.nombre}
				onChange={handleChange}
			  />
			</Form.Group>
			<Form.Group controlId="formCorreo">
			  <Form.Label>Correo</Form.Label>
			  <Form.Control
				type="email"
				placeholder="Correo"
				name="correo"
				value={formData.correo}
				onChange={handleChange}
			  />
			</Form.Group>
			<Form.Group controlId="formTelefono">
			  <Form.Label>Teléfono</Form.Label>
			  <Form.Control
				type="tel"
				placeholder="Teléfono"
				name="telefono"
				value={formData.telefono}
				onChange={handleChange}
			  />
			</Form.Group>
			<Form.Group controlId="formDireccion">
			  <Form.Label>Dirección</Form.Label>
			  <Form.Control
				type="text"
				placeholder="Dirección"
				name="direccion"
				value={formData.direccion}
				onChange={handleChange}
			  />
			</Form.Group>
			<Form.Group controlId="formRol">
			  <Form.Label>Rol</Form.Label>
			  <Form.Control
				as="select"
				name="rol"
				value={formData.rol}
				onChange={handleChange}
			  >
				<option value="colaborador">Colaborador</option>
				<option value="cliente">Cliente</option>
			  </Form.Control>
			</Form.Group>
			<Form.Group controlId="formCodCargo">
			<Form.Label>Cargo</Form.Label>
			<Form.Select
			  name="cod_cargo"
			  value={formData.cod_cargo}
			  onChange={handleChange}
			  disabled={formData.rol !== 'colaborador'}
			>
			  <option value={undefined}>No asignado</option>
			  {cargos.map((cargo) => (
				<option key={cargo.cod_cargo} value={cargo.cod_cargo}>
				  {cargo.nombre}
				</option>
			  ))}
			</Form.Select>
			</Form.Group>
			<Form.Group controlId="formPassword">
			  <Form.Label>Cambiar Contraseña</Form.Label>
			  <Form.Control
				type="password"
				placeholder="Nueva Contraseña"
				name="password"
				value={formData.password}
				onChange={handleChange}
			  />
			</Form.Group>
			<Button className="mt-3" variant="primary" type="submit">
			  Guardar Cambios
			</Button>
		  </Form>
		</Modal.Body>
	  </Modal>
	);
  };



export const UsuariosList = () => {
	const [Usuarios, setUsuarios] = useState([]);
	
	const [loading, setLoading] = useState(true);

	const getUsuarios = async () => {		
		const data = await getApiData("persona");
		setUsuarios(data);
		setLoading(false);
	};
	
	useEffect(() => {
		getUsuarios();
	},[]);


    return (!loading && <CustomDataTable title="Usuarios" columns={columns} list={Usuarios} ModalEdit={ModalEditUsuarios} filterField={"nombre"} cpField={"cedula"}/>);
}