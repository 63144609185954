import Button from "react-bootstrap/Button";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';


export const NavBar = ({handleLogout}) => {
    return (
        <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
          <Container>
          <Navbar.Brand href="/">
                <img
                alt=""
                src="/media/logo.png"
                height="30"
                className="d-inline-block align-top"
                />{' '}
                Agribusiness Ecuador
                </Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
            
              <Nav className="me-auto">
                <NavDropdown title="Usuarios" id="collasible-nav-dropdown">
                    <NavDropdown.Item href="alta-usuarios">Nuevo Usario</NavDropdown.Item>
                    <NavDropdown.Item href="lista-usuarios">Lista de Usuarios</NavDropdown.Item>
                </NavDropdown>
                
                <NavDropdown title="Productos" id="collasible-nav-dropdown">
                    <NavDropdown.Item href="alta-productos">Nuevo Producto</NavDropdown.Item>
                    <NavDropdown.Item href="lista-productos">Lista de Productos</NavDropdown.Item>
                </NavDropdown>
                
                <NavDropdown title="Eventos" id="collasible-nav-dropdown">
                    <NavDropdown.Item href="alta-eventos">Nuevo Evento</NavDropdown.Item>
                    <NavDropdown.Item href="lista-eventos">Lista de Eventos</NavDropdown.Item>
                </NavDropdown>

                <NavDropdown title="Artículos Técnicos" id="collasible-nav-dropdown">
                    <NavDropdown.Item href="alta-articulos">Nuevo Artículo</NavDropdown.Item>
                    <NavDropdown.Item href="lista-articulos">Lista de Artículos</NavDropdown.Item>
                </NavDropdown>
                
              </Nav>
              <Nav.Link>
                <Button variant="outline-light" onClick={handleLogout}>Cerrar sesion</Button>
              </Nav.Link>
              
            </Navbar.Collapse>
          </Container>
        </Navbar>
      );
    }